<template>
  <div class="channelOne">
    <div class="logoTop">
      <img
        src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/logo.png"
        alt=""
      />
    </div>
    <div class="bigTitle">账号密码登录</div>
    <div class="smallTitle">请使用已注册的账号密码</div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
    >
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="请输入账号"
        >
          <img
            src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/phone.png"
            alt=""
            slot="prefix"
            class="el-input__icon input-icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="请输入密码"
        >
          <img
            src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/suo.png"
            alt=""
            slot="prefix"
            class="el-input__icon input-icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="code" v-if="captchaEnabled" class="yanzhengma">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 53%"
        >
          <!-- <svg-icon
            slot="prefix"
            icon-class="validCode"
            class="el-input__icon input-icon"
          /> -->
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img" />
        </div>
      </el-form-item>
    </el-form>
    <div class="denglu" @click="handleLogin">登录</div>
  </div>
</template>

<script>
// import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import { getToken } from "@/utils/auth";
// import { login, logout, getInfo } from "@/api/login";
export default {
  name: "channelOne",
  components: {},
  data() {
    return {
      // 验证码开关
      captchaEnabled: true,
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
      },
    };
  },
  created() {
    this.getCode();
  },
  mounted() {},
  methods: {
    getCode() {
      this.api.captchaImage().then((res) => {
        console.log(res);
        this.captchaEnabled =
          res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.uuid = res.data.uuid;
        }
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.api
            .h5login(this.loginForm)
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                localStorage.setItem("h5userName", this.loginForm.username);
                Cookies.set("Admin-Token", res.data.token);

                this.$router.push({ path: "/channelTwo" });
              }
            })
            .catch(() => {
              this.loading = false;
              if (this.captchaEnabled) {
                this.getCode();
              }
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.channelOne {
  width: 100%;
  height: 100vh;
  background-image: url("https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/bg.png");
  background-size: 100% 100%;
}
.logoTop {
  width: 90px;
  margin: 0 auto;
  padding-top: 74px;
  margin-bottom: 36px;
}
.logoTop img {
  width: 90px;
  height: 90px;
}
.bigTitle {
  font-size: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
}
.smallTitle {
  font-size: 13px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 15px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 35px;
}
div /deep/.el-form {
  width: 343px;
  margin: 0 auto;
}
.el-input--medium,
div /deep/.el-input__inner,
.el-input {
  width: 343px;
  height: 50px !important;
  background: #ffffff;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
}

div /deep/.el-input__icon {
  width: 24px;
  height: 24px;
}
div /deep/.el-input__prefix {
  position: absolute;
  height: 100%;
  left: 5px;
  top: 13px;
  text-align: center;
  color: #c0c4cc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.denglu {
  width: 343px;
  height: 50px;
  background: #03549e;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin: 70px auto;
}

.yanzhengma /deep/.el-input__inner,
.el-input {
  width: 200px;
  height: 50px !important;
  background: #ffffff;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
}
.login-code {
  width: 130px;
  height: 50px;
  float: right;
  overflow: hidden;
}
.login-code img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
</style>
